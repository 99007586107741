<template>
  <q-dialog v-model="showDlg" persistent>
    <q-card style="width: 600px; max-width: 60vw">
      <q-bar class="bg-primary text-white">
        <q-icon name="account_box" />
        <div>
          用户信息<small class="q-ml-sm text-size-sm">{{
            UserInfo.UserID
          }}</small>
        </div>

        <q-space />

        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip>关闭</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section class="q-pt-none">
        <q-form>
          <q-input label="用户编码" v-model="UserInfo.UserCode" />
          <q-input label="姓名" v-model="UserInfo.UserName" />
          <q-select
            label="性别"
            v-model="UserInfo.Sex"
            emit-value
            map-options
            :options="[
              { value: 1, label: '男' },
              { value: 2, label: '女' },
            ]"
          >
          </q-select>
          <q-input label="手机号" v-model="UserInfo.Phone" />
          <q-input label="电子邮箱" v-model="UserInfo.Email" />
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="确定" @click="onUpdateUserInfo" />
        <q-btn flat label="取消" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState("SysUser", ["UserEntity", "ShowDlg"]),
    ...mapGetters("appUser", ["hospID"]),
    showDlg: {
      get() {
        return this.ShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("SysUser/setShowDlg", val);
      },
    },
  },
  watch: {
    showDlg(val) {
      if (val) {
        Object.assign(this.UserInfo, this.UserEntity);
      } else {
        this.UserInfo = {};
      }
    },
  },
  data() {
    return {
      loading: false,
      UserInfo: {},
    };
  },
  methods: {
    ...mapActions("SysUser", ["actUpdateSysUser"]),
    onUpdateUserInfo() {
      this.loading = true;
      this.actUpdateSysUser({
        HospID: this.hospID,
        UserEntity: this.UserInfo,
      })
        .then((res) => {
          if (res.Code != 0) this.msg.error(res.Message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>